import { CardType } from '@websky/core/src/PromoLoader/types';
import { getLocale } from '@websky/core/src/utils';
import { OauthServiceType } from '@websky/graphql';
var currentLocale = getLocale();
var isProd = process.env.NODE_ENV === 'production';
export var passengerTypeConfigs = [];
export var companyInfo = {
    iataCode: 'KV',
    privacyPolicy: "https://www.krasavia.ru/".concat(currentLocale, "/politika-konfidencialnosti")
};
export var i18n = {
    ru: {
        Checkout: {
            pet_popup_rules: [
                'Вес домашнего животного (вместе с контейнером, переноской или клеткой) не более 10 кг.',
                'Габариты контейнера (переноски/клетки) не более 55*40*20 см, для самолета L410 - не более 30*25*25 см, для самолетов АН-26 - не более 45*30*20 см.',
                'Дно контейнера (переноски/клетки) должно быть плотным и водонепроницаемым, а с внутренней стороны покрыто абсорбирующей пеленкой или материалом (при этом контейнер должен исключать возможность рассыпания).',
                'Контейнер (переноска/клетка) должны позволять животному встать, а клетка для птиц должна быть покрыта плотной светонепроницаемой тканью.',
                '',
                ''
            ],
            'Take your pet in a trip': 'Возьмите в путешествие домашнее животное'
        },
        Title: {
            companyName: 'Красавиа'
        },
        MealService: {
            All: 'Все блюда'
        },
        PromoLoader: {
            'Meal.title': 'Питание',
            'Meal.text': 'Попробуйте различные позиции горячих блюд, сэндвичей, вегетарианский и детский наборы, а также снэки и выпечку!',
            'Mongolia.title': 'Монголия',
            'Mongolia.text': 'В Монголию из Красноярска: рейсы 2 раза в неделю. Удобные стыковки на рейсы в Гонконг, Пекин и Сеул!',
            'Services.title': 'Услуги',
            'Services.text': 'Закажите все услуги на нашем сайте и наслаждайтесь поездкой!',
            'Service.title': 'Сервис',
            'Service.text': 'Обеспечим качественный сервис на протяжении всего пути. До встречи на борту!'
        },
        LoginPage: {
            'Email / Phone / Card number': 'Эл. почта',
            'Please enter a valid Email / Phone / Card number': 'Пожалуйста, введите Эл. почту'
        },
        Consents: {
            agreement_rules: 'Я ознакомился(-лась) с {{links}}, принимаю их и согласен с ними.',
            agreement_gdsRules: ', с условиями предоставления выбранных мной дополнительных услуг и их оплатой',
            agreement_generalRules: "<span><a href='https://krasavia.ru/storage/app/media/docs/pravila-vozdushnyh-perevozok-passajirov-bagaja-gruzov-04.24.pdf' target='_blank'>\u043F\u0440\u0430\u0432\u0438\u043B\u0430\u043C\u0438 \u0430\u0432\u0438\u0430\u043A\u043E\u043C\u043F\u0430\u043D\u0438\u0438</a></span>",
            agreement_insurancesRules: "<a href='https://www.alfastrah.ru/docs/insurance_passengers_rules_284.pdf' target='_blank'>\u043F\u0440\u0430\u0432\u0438\u043B\u0430\u043C\u0438 \u0441\u0442\u0440\u0430\u0445\u043E\u0432\u0430\u043D\u0438\u044F</a>, \u0430 \u0442\u0430\u043A\u0436\u0435 {{insurancesProductsLinks}}"
        },
        IncludedBaggage: {
            'Need to bring sports, music or baby equipments?': 'Перевозка одной единицы оружия до 20кг'
        },
        CheckinConditions: {
            'Check-in conditions': 'Онлайн-регистрация открывается за 30 часов и закрывается за 50 минут до вылета.',
            BaggageCondition: 'Выдача багажа закрывается за 40 минут до вылета рейса. Пожалуйста, убедитесь, что вес вашей ручной клади составляет до 10 кг, а размер не превышает 55x40x23 см.',
            PassengerCondition: 'Пожалуйста, убедитесь, что ваш пассажир не относится к особенной категории',
            Rules_0: 'Онлайн-регистрация открывается за 30 часов и закрывается за 50 минут до вылета.',
            Rules_1: 'Выдача багажа закрывается за 40 минут до вылета рейса. Пожалуйста, убедитесь, что вес вашей ручной клади составляет до 10 кг, а размер не превышает 55x40x23 см.',
            Rules_2: 'Пожалуйста, убедитесь, что ваш пассажир не относится к особенной категории'
        }
    },
    en: {
        Title: {
            companyName: 'Krasavia'
        },
        MealService: {
            All: 'All meals'
        },
        Checkout: {
            pet_popup_rules: [
                'The total weight of the pet and its container/carrier/cage must not exceed 10 kg (22 lbs).',
                'The maximum dimensions for the container/carrier/cage are 55 x 40 x 20 cm (21.7 x 15.7 x 7.9 inches). For L410 aircraft, the maximum dimensions are 30 x 25 x 25 cm (11.8 x 9.8 x 9.8 inches). For AN-26 aircraft, the maximum dimensions are 45 x 30 x 20 cm (17.7 x 11.8 x 7.9 inches).',
                'The container/carrier/cage must have a solid, waterproof bottom lined with an absorbent pad or material (to prevent spills).',
                'The container/carrier/cage must allow the animal to stand up comfortably. Bird cages must be covered with a dense, opaque fabric.',
                '',
                ''
            ],
            'Take your pet in a trip': 'Take your pet in a trip'
        },
        PromoLoader: {
            'Meal.title': 'Meal',
            'Meal.text': 'Try a variety of hot dishes, sandwiches, vegetarian and children\'s sets, as well as snacks and baked goods!',
            'Mongolia.title': 'Mongolia',
            'Mongolia.text': 'To Mongolia from Krasnoyarsk: flights 2 times a week. Convenient connections for flights to Hong Kong, Beijing and Seoul!',
            'Services.title': 'Services',
            'Services.text': 'Order all services on our website and enjoy the trip!',
            'Service.title': 'Service',
            'Service.text': 'We will provide quality service throughout the journey. See you on board!'
        },
        LoginPage: {
            'Email / Phone / Card number': 'Email',
            'Please enter a valid Email / Phone / Card number': 'Please enter a valid Email'
        },
        Consents: {
            agreement_generalRules: "<span><a href='https://krasavia.ru/storage/app/media/docs/pravila-vozdushnyh-perevozok-passajirov-bagaja-gruzov-04.24.pdf' target='_blank'>rules of purchase</a></span>",
            agreement_insurancesRules: "<a href='https://www.alfastrah.ru/docs/insurance_passengers_rules_284.pdf' target='_blank'>rules of insurances</a>, \u0430 \u0442\u0430\u043A\u0436\u0435 \u0443\u0441\u043B\u043E\u0432\u0438\u044F\u043C\u0438 {{insurancesProductsLinks}}"
        },
        IncludedBaggage: {
            'Need to bring sports, music or baby equipments?': 'Transportation of one weapon up to 20 kg'
        },
        CheckinConditions: {
            Rules_0: 'Online check-in opens 30 hours and closes 50 minutes before departure',
            Rules_1: 'Baggage claim closes 40 minutes before flight departure. Please, check that your hand luggage weight is up to 10 kg and size does not exceed 55x40x23 cm',
            Rules_2: 'Please, make sure te passenger is not included in special category',
            'Check-in conditions': 'Online check-in opens 30 hours and closes 50 minutes before departure',
            BaggageCondition: 'Baggage claim closes 40 minutes before flight departure. Please, check that your hand luggage weight is up to 10 kg and size does not exceed 55x40x23 cm',
            PassengerCondition: 'Please, make sure te passenger is not included in special category'
        }
    }
};
export var promoMessages = [];
export var promoLoaders = [
    {
        type: CardType.Meal,
        title: 'Meal.title',
        text: 'Meal.text',
        image: 'https://cdn.websky.aero/content/frontend/images/kv-loading-banners/meal-01.jpg'
    },
    {
        type: CardType.City,
        title: 'Mongolia.title',
        text: 'Mongolia.text',
        image: 'https://cdn.websky.aero/content/frontend/images/kv-loading-banners/mongolia-01.jpg'
    },
    {
        type: CardType.Aircraft,
        title: 'Services.title',
        text: 'Services.text',
        image: 'https://cdn.websky.aero/content/frontend/images/kv-loading-banners/services-01.jpg'
    },
    {
        type: CardType.Aircraft,
        title: 'Service.title',
        text: 'Service.text',
        image: 'https://cdn.websky.aero/content/frontend/images/kv-loading-banners/service-01.jpg'
    }
];
export var config = {
    global: {
        yandexMetrikaId: '97672201',
        findOrderURL: './',
        showIsActualizationResultOk: true,
        i18n: i18n,
        FFPRegex: /^\d{8}$/,
        allowPromoCodes: false,
        companyInfo: companyInfo,
        servicesAvailable: {
            animal: true
        },
        reserveEngines: {
            siteUrl: 'https://krasavia.ru/'
        },
        insurance: {
            upsaleStrategy: ['checkout-insurance-step']
        },
        socialAuthMethods: [
            { service: OauthServiceType.Google, isAvailable: true },
            { service: OauthServiceType.Vkontakte, isAvailable: true }
        ],
        authMethods: {
            phone: false,
            ffp: false
        },
        seatMap: {
            splitByCabin: true
        }
    },
    Account: {
        engineURL: './booking'
    },
    Engine: {
        calendarDaysCount: 7,
        promoMessages: promoMessages,
        searchFormURL: isProd ? './' : null,
        checkInURL: "./check-in",
        checkInEnabled: true,
        promoLoaders: promoLoaders,
        resultsFareSelectorInModal: false
    },
    SearchForm: {
        payByMiles: false,
        showPricesInDatepicker: true,
        enableComplexRoute: true,
        passengersConfig: [
            {
                code: 'ADT',
                isAdult: true,
                defaultCount: 1
            },
            {
                code: 'CLD',
                isAdult: false,
                maxCountWithoutAdult: 0
            },
            {
                code: 'INF',
                isAdult: false
            },
            {
                code: 'INS',
                isAdult: false
            }
        ]
    }
};
